import React from 'react';
import CacheBuster from 'react-cache-buster';
import App from './App';
const { version } = require('../package.json');

const App2 = () => {
  const isProduction = process.env.NODE_ENV === 'production';
  return (
    <CacheBuster
        currentVersion={version}
        isEnabled={isProduction}
        isVerboseMode={false}
        metaFileDirectory={'.'}
        onCacheClear={() => {
            console.log('Cache cleared');
            window.location.reload();
        }}
    >

      <App />

    </CacheBuster>
  );
};

export default App2;