import axios from 'axios'

// DOES NOT NEED TOKEN

// registers user into AP DB
export const isUserInEADB = async (userData) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_EA_API}/user/signin`, {
            email: userData.email,
            password: userData.password,
        })
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

/*
// gets membership status from EA DB with token
export const getMembershipStatusInEADB = async (userData) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_EA_API}/membership/my-subscription`, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        });
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}
*/

// sets membership status from EA DB without token for users with linked accounts
export const setMembershipStatus = async (userData) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_AP_API}/setMembershipStatus`, {
        }, {headers: {
            'Authorization': `Bearer ${userData.token}`
        }})
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// checks if user (based on email/pass) is in AP DB
export const isUserInAPDB = async (userData) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_AP_API}/isUserInAPDB`, {
            email: userData.email,
        })
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// logs in user 
export const login = async (userData) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_AP_API}/login`, {
            email: userData.email,
            password: userData.password,
        })
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// registers user into AP DB
export const register = async (userData) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_AP_API}/register`, {
            email: userData.email,
            password: userData.password,
            eaID: userData.eaID,
            ip: userData.ip,
            member: userData.member,
        })
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// registers user into AP DB (linked accounts)
export const register2 = async (userData) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_AP_API}/register2`, {
            email: userData.email,
            supabaseID: userData.supabaseID
        }, {headers: {
            'Authorization': `Bearer ${userData.token}`
        }})
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// used to check if user is verified
export const getIsVerified = async (userData) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_AP_API}/getIsVerified`, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        });
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// gets user's wallet balance
export const getWalletBalance = async (userData) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_AP_API}/getWalletBalance`, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        });
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// gets user's promo balance
export const getPromoBalance = async (userData) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_AP_API}/getPromoBalance`, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        });
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// gets user's wallet and promo balance (for navbar)
export const getBalances = async (userData) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_AP_API}/getBalances`, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        });
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// gets user's free entry balance
export const getFreeEntryBalance = async (userData) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_AP_API}/getFreeEntryBalance`, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        });
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// gets all active players on board
export const getBoard = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_AP_API}/getBoard`);
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// gets all active players on board
export const getBoardWithSearch = async (userData) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_AP_API}/getBoardWithSearch?query=${userData.query}`);
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// registers user into AP DB
export const submitPicks = async (userData) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_AP_API}/submitPicks`, {
            picks: userData.picks,
            freeEntry: userData.freeEntry,
            promoCode: userData.promoCode,
            betAmount: userData.betAmount  
        }, {headers: {
            'Authorization': `Bearer ${userData.token}`
        }})
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// gets a user's entries (from users DB)
export const getEntries = async (userData) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_AP_API}/getEntries`, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        });
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// gets board player data from entry/bet id
export const getPicks = async (userData) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_AP_API}/getPicks?id=${userData.id}`, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        });
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

export const getUserInfo = async (userData) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_AP_API}/getUserInfo`, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        });
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// gets a user's settings (from users DB)
export const getSettings = async (userData) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_AP_API}/getSettings`, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        });
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// saves a users settings (phone, creator code, isVerified) into users DB
export const saveSettings = async (userData) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_AP_API}/saveSettings`, {
            phone: userData.phone,
            creatorCode: userData.creatorCode,
            referralCode: userData.referralCode
        }, {headers: {
            'Authorization': `Bearer ${userData.token}`
        }})
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// saves a users phone into users DB
export const savePhone = async (userData) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_AP_API}/savePhone`, {
            phone: userData.phone,
        }, {headers: {
            'Authorization': `Bearer ${userData.token}`
        }})
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// saves a users verification info (firstname, lastname, address, location, isVerified) into users DB
export const saveVerificationInfo = async (userData) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_AP_API}/saveVerificationInfo`, {
            inquiryID: userData.inquiryID
        }, {headers: {
            'Authorization': `Bearer ${userData.token}`
        }})
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// gets user's wallet, promo, and free entry balance + boost stuff
export const getCartInfo = async (userData) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_AP_API}/getCartInfo`, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        });
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// gets a user's promotions page data (from users DB)
export const getPromotions = async (userData) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_AP_API}/getPromotions`, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        });
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// gets a user's DETAILED promotions page data (from users DB)
export const getPromotions2 = async (userData) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_AP_API}/getPromotions2`, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        });
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// deposits money into user's wallet (non-withdrawable)
export const deposit = async (userData) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_AP_API}/deposit`, {
            amount: userData.amount,
            paymentNonce: userData.paymentNonce,
            lastFour: userData.lastFour
        }, {headers: {
            'Authorization': `Bearer ${userData.token}`
        }})
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        if (err?.response?.status === 429) {
            return {status: 429, errorObj: {type: "other", msg: "Too many requests. Please try again in a few minutes"}}
        }
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}


// gets important user info for withdraw
export const getUserInfoForWithdraw = async (userData) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_AP_API}/getUserInfoForWithdraw`, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        });
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// 
export const createDotsUser = async (userData) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_AP_API}/createDotsUser`, {
            first_name: userData.first_name,
            last_name: userData.last_name,
            country_code: userData.country_code,
            phone_number: userData.phone_number,
            email: userData.email,
        }, {headers: {
            'Authorization': `Bearer ${userData.token}`
        }})
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// 
export const getDotsVerified = async (userData) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_AP_API}/getDotsVerified`, {
            code: userData.code,
        }, {headers: {
            'Authorization': `Bearer ${userData.token}`
        }})
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// 
export const getVerificationCode = async (userData) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_AP_API}/getVerificationCode`, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        });
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

//
export const requestWithdraw = async (userData) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_AP_API}/requestWithdraw`, {
            amount: userData.amount
        }, {headers: {
            'Authorization': `Bearer ${userData.token}`
        }})
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

//
export const acceptRejection = async (userData) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_AP_API}/acceptRejection`, {

        }, {headers: {
            'Authorization': `Bearer ${userData.token}`
        }})
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// 
export const getDotsPayoutLink = async (userData) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_AP_API}/getDotsPayoutLink`, {

        }, {headers: {
            'Authorization': `Bearer ${userData.token}`
        }})
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// send location service info to backend for logs
export const sendLocationData = async (userData) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_AP_API}/sendLocationData`, {
            location: userData.location
        }, {headers: {
            'Authorization': `Bearer ${userData.token}`
        }})
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// send location service info to backend for logs
export const sendLocationData2 = async (userData) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_AP_API}/sendLocationData2`, {
            info: userData.info
        }, {headers: {
            'Authorization': `Bearer ${userData.token}`
        }})
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}


















export const checkAdmin = async (userData) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_AP_API}/checkAdmin`, {

        }, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        })
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// gets general user info
export const adminGetGeneral = async (userData) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_AP_API}/adminGetGeneral`, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        });
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// gets ALL players on board
export const adminGetBoard = async (userData) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_AP_API}/adminGetBoard?active=${userData.active}`, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        });
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// creates new document in boards collection
export const adminCreatePlayer = async (userData) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_AP_API}/adminCreatePlayer`, {
            name: userData.name,
            game: userData.game,
            team: userData.team,
            opp: userData.opp,
            date: userData.date,
            maps: userData.maps,
            stat: userData.stat,
            predicted: userData.predicted,
            finished: userData.finished,
            expired: userData.expired,
            pro: userData.pro,
            img: userData.img
        }, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        })
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// deletes a player document in boards collection
export const adminDeletePlayer = async (userData) => {
    try {
        const res = await axios.delete(`${process.env.REACT_APP_AP_API}/adminDeletePlayer/${userData.id}`, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        })
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// edits player document in boards collection
export const adminEditPlayer = async (userData) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_AP_API}/adminEditPlayer`, {
            id: userData._id,
            name: userData.name,
            game: userData.game,
            team: userData.team,
            opp: userData.opp,
            matchDateTimeEST: userData.matchDateTimeEST,
            maps: userData.maps,
            stat: userData.stat,
            predicted: userData.predicted,
            actual: userData.actual,
            finished: userData.finished,
            expired: userData.expired,
            pro: userData.pro,
            img: userData.img,
            pickedUnder: userData.pickedUnder,
            pickedOver: userData.pickedOver,
            special: userData?.special ? userData?.special : "",
        }, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        })
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

//
export const adminEditPlayerLine = async (userData) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_AP_API}/adminEditPlayerLine`, {
            id: userData._id,
            name: userData.name,
            game: userData.game,
            team: userData.team,
            opp: userData.opp,
            matchDateTimeEST: userData.matchDateTimeEST,
            maps: userData.maps,
            stat: userData.stat,
            predicted: userData.predicted,
            actual: userData.actual,
            finished: userData.finished,
            expired: userData.expired,
            pro: userData.pro,
            img: userData.img,
            pickedUnder: userData.pickedUnder,
            pickedOver: userData.pickedOver,
            special: userData?.special ? userData?.special : ""
        }, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        })
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// gets ALL users data
export const adminGetUsers = async (userData) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_AP_API}/adminGetUsers`, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        });
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// Bans a user in users collection
export const adminBanUser = async (userData) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_AP_API}/adminBanUser`, {
            id: userData.id
        }, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        })
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// edits a user in users collection
export const adminEditUser = async (userData) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_AP_API}/adminEditUser`, {
            id: userData._id,
            member: userData.member,
            walletBalance: userData.walletBalance,
            winningsWalletBalance: userData.winningsWalletBalance,
            promoBalance: userData.promoBalance,
            freeEntryBalance: userData.freeEntryBalance,
            limit: userData.limit,
            cardsLimit: userData.cardsLimit,
            banned: userData.banned,
        }, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        })
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// gets ALL entries data
export const adminGetEntries = async (userData) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_AP_API}/adminGetEntries`, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        });
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// gets ALL deposits data
export const adminGetDeposits = async (userData) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_AP_API}/adminGetDeposits`, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        });
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// gets ONLY withdraw requests
export const adminGetWithdrawRequests = async (userData) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_AP_API}/adminGetWithdrawRequests`, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        });
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// responds to user's withdraw request with -1 (deny) or 1 (approved)
export const adminRespondToWithdrawRequest = async (userData) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_AP_API}/adminRespondToWithdrawRequest`, {
            id: userData.id,
            adminResponse: userData.adminResponse
        }, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        })
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        if (err?.response?.status === 429) {
            return {status: 429, errorObj: {type: "other", msg: "Too many requests. Please try again in a few minutes"}}
        }
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// gets ALL withdraws data
export const adminGetWithdraws = async (userData) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_AP_API}/adminGetWithdraws`, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        });
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// gets ALL creator codes data
export const adminGetCCs = async (userData) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_AP_API}/adminGetCCs`, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        });
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// edits a user in users collection
export const adminEditCC = async (userData) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_AP_API}/adminEditCC`, {
            id: userData._id,
            code: userData.code,
            active: userData.active,
            matching: userData.matching
        }, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        })
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}

// creates new document in creatorCodes collection
export const adminCreateCC = async (userData) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_AP_API}/adminCreateCC`, {
            code: userData.code,
            active: userData.active,
            matching: userData.matching
        }, {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        })
        // console.log('res:', res.data)
        return res.data
    } catch (err) {
        // console.log('err:', err)
        return {status: 500, errorObj: {type: "server", msg: "Server Error"}}
    }
}
